import BagsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/bags/BagsTemplate';

import { EYE_BAGS_TYPES } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/Bags/BagsTemplate'
};

const createStory = props => () => ({
  components: { BagsTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <bags-template v-bind="$options.storyProps" />
  </div>`
});

export const OneQuestion = createStory({
  eyeBags: 'yes'
});

export const TwoQuestions = createStory({
  eyeBags: 'yes',
  eyeBagsType: EYE_BAGS_TYPES.FLUID,
  isEyeBagsTypeVisible: true
});
